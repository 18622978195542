<template>
  <v-container fluid class="pa-0">
    <v-layout class="justify-center align-center" style="height: 100vh">
      <v-card flat tile color="transparent" width="100%">
        <v-card-text class="px-3">
          <div class="mb-12 pb-12 text-center">
            <b v-text="welcomeText.title"></b>
            <v-tooltip bottom color="white" content-class="opacity1 black--text">
              <template v-slot:activator="{ on }">
                <v-icon right v-on="on" small>fas fa-info-circle</v-icon>
              </template>

              <v-card outlined class="pa-3" v-html="welcomeText.text"></v-card>
            </v-tooltip>
          </div>

          <v-tabs
            v-model="active"
            background-color="transparent"
            color="primary"
            centered
            center-active
            :show-arrows="false"
            class="login-tabs">
            <v-tab v-for="tab in tabs" :key="tab.id" class="">
              {{ tab.name }}
            </v-tab>

            <v-tab-item v-for="tab in tabs" :key="tab.id" class="pt-5 pa-2">
              <component
                :ref="`form_${tab.id}`"
                :is="`${tab.template}-template`"
                @input="setData($event, tab.id)"
                @onSignIn="onSignIn"
                v-bind="tab" />
            </v-tab-item>
          </v-tabs>

          <div class="mt-3">
            <v-btn @click="onSignIn" :value="alert" block color="primary">
              {{ $t('Sign In') }}
            </v-btn>
          </div>

          <template v-if="icons.length">
            <v-divider class="my-4" />

            <p class="subtitle mb-0 text-center">
              {{ $t('You can use one of the following') }}
            </p>

            <div class="text-center mt-2">
              <v-tooltip v-for="icon in icons" :key="icon.id" top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    @click="signInByOpenWindow(icon)"
                    :color="icon.iconColor || 'white'"
                    slot="activator"
                    fab
                    small
                    class="ma-1 elevation-3">
                    <v-icon small v-bind="icon.iconCustomProps">{{ icon.icon }}</v-icon>
                  </v-btn>
                </template>

                <span>{{ icon.tooltip }}</span>
              </v-tooltip>
            </div>
          </template>

          <v-alert ref="alertMsg" :value="alert" type="error" transition="scale-transition">
            {{ alertMsg }}
          </v-alert>
        </v-card-text>
      </v-card>
    </v-layout>
  </v-container>
</template>

<script>
  import Vue from 'vue';
  import { mapActions, mapGetters, mapState } from 'vuex';

  const feature = `<b>Я умею:</b><br/><ul><li>отвечать на часто задаваемые вопросы;</li><li>найти документ (инструкцию) на заданную тему;</li><li>узнать статус заявки;</li><li>работать с основными данными (например, изменить название поставщика);</li><li>разрешить проблемы с логином в корпоративные системы;</li><li>создать заказ;</li><li>и многое другое...</li></ul>`;
  // `<b>Умею отвечать на часто задаваемые вопросы по закупкам в НЛМК:</b><br/><ul><li>квалификационные анкеты, причины отклонения;</li><li>участие в закупочных процедурах;</li><li>работа в личном кабинете поставщика;</li></ul><br/><b>Зарегистрированным поставщикам помогу по вопросам:</b><br/><ul><li>статус квалификации;</li><li>статус платежа.</li></ul>`

  const CustomTemplate = () => import('./components/CustomTemplate/CustomTemplate.vue');
  const LocalGuestTemplate = () => import('./components/LocalGuestTemplate/LocalGuestTemplate.vue');
  const LoginTemplate = () => import('./components/LoginTemplate/LoginTemplate.vue');

  export default {
    name: 'Login',
    components: {
      'custom-template': CustomTemplate,
      'local-guest-template': LocalGuestTemplate,
      'login-template': LoginTemplate,
    },
    data: function () {
      return {
        active: undefined,
        alert: false,
        alertMsg: 'There was an error',
        data: {},
        showTooltip: {},
      };
    },
    computed: {
      ...mapGetters('adapters', ['tabs', 'icons']),
      ...mapGetters('system', ['welcomeText']),
    },
    methods: {
      ...mapActions('config', ['fetchConfigs']),
      ...mapActions('adapters', ['fetchAdapters', 'signInByRequest', 'signInByOpenWindow']),
      ...mapActions('system', ['getWelcomeText']),

      initTabs: async function () {
        await this.fetchAdapters();
        this.active = this.tabs.findIndex(item => item.isDefault);
      },

      onSignIn: async function () {
        const activeForm = this.tabs[this.active];
        const valid = await this.$refs[`form_${activeForm.id}`][0].validate();
        if (valid) {
          this.signInByRequest({ form: activeForm, data: this.data[activeForm.id] });
        }
      },

      setData: function (value, id) {
        Vue.set(this.data, id, value);
      },

      loadConfigs: async function () {
        this.fetchConfigs();
      },
    },
    created: function () {
      this.initTabs();
      this.loadConfigs();
      this.getWelcomeText();
    },
  };
</script>

<style>
  .login-tabs .v-slide-group__prev {
    display: none !important;
  }

  .opacity1 {
    opacity: 1 !important;
  }
</style>
